import React from "react";
import Header from "../components/Header";
import Quote from "../components/Quote";
import Layout, { PageLayout } from "../components/Layout";
import DoubleLine from "../components/DoubleLine";
import {
  MaybeStickyTitle,
  Title,
  TitleDescription,
} from "../components/Titles";
import Nest from "../components/Nest";
import timeline from "../constants/timeline.json";
import styled from "styled-components";
import { Link } from "gatsby";
import { Webflow, Github } from "../components/Social";
import { ImageItem, Contain, LinksLayout } from "../components/ImageContainer";

const Item = styled.div`
  padding-bottom: 16px;
`;

const Headline = styled.h2`
  margin-bottom: 8px;
`;

const DateText = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

const Content = styled.div`
  & img {
    border: 1px solid var(--element-muted-color);
    max-width: 100%;
    box-sizing: border-box;
    padding: 8px;
  }
`;

const Meta = styled.div`
  display: flex;
`;

const Space = styled.div`
  height: 40px;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    height: 20px;
  }
`;

const Links = () => (
  <Contain>
    <LinksLayout>
      <ImageItem
        href="https://webflow.com/"
        image={<Webflow height={160} />}
        description="I work full time as a Senior Staff Software Engineer at Webflow."
      />
      <ImageItem
        href="https://github.com/iammerrick"
        image={<Github height={160} />}
        description="I contribute to several repositories on Github."
      />
    </LinksLayout>
  </Contain>
);

const HeadlineLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

class WorkPage extends React.Component {
  render() {
    return (
      <PageLayout slug={this.props.location.pathname}>
        <Nest>
          <Header />
          <Quote
            content="The goodness of the world in which we live is the accumulated goodness of many small and seemingly inconsequential acts."
            source="Gordon B Hinkley"
          />
          <Layout
            left={
              <MaybeStickyTitle>
                <Title>Present</Title>
                <TitleDescription>
                  Grinding, hustling and other varieties of work. See my{" "}
                  <Link to="/user-guide">user guide</Link> to leave me feedback.
                </TitleDescription>
              </MaybeStickyTitle>
            }
            right={<Links />}
          />
          <Space />
          <DoubleLine />
          <Space />
          <Layout
            left={
              <MaybeStickyTitle>
                <Title>Past</Title>
                <TitleDescription>
                  This is more of a personal work journal than a portfolio. See
                  my{" "}
                  <Link to="/resume" target="_blank">
                    resume
                  </Link>{" "}
                  for the polished highlights.
                </TitleDescription>
              </MaybeStickyTitle>
            }
            right={
              <Content>
                <Contain>
                  {timeline.timeline
                    .sort(
                      (a, b) => new Date(b.startDate) - new Date(a.startDate)
                    )
                    .map((item, index) => (
                      <Item key={index}>
                        <HeadlineLayout>
                          <Headline>{item.headline}</Headline>
                          <div>{item.tag}</div>
                        </HeadlineLayout>
                        <div>
                          <Meta>
                            <DateText>{item.startDate}</DateText>
                          </Meta>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          />
                        </div>
                      </Item>
                    ))}
                </Contain>
              </Content>
            }
          />
        </Nest>
      </PageLayout>
    );
  }
}
export default WorkPage;
